import HeadLine from "../headLine";
import { newseLatterData } from "../../data/newseLatterData";
import Image from "next/image";

const NewseLatter = ({ bgWhite = true }) => {
  return (
    <section className="dark:bg-jacarta-800 relative py-24">

      <div className="container">
        <HeadLine
          text="Join our mailing list to stay in the loop with our newest info"
          classes="font-display text-jacarta-700 mb-16 text-center text-3xl dark:text-white"
        />

        <div className="mx-auto mt-7 max-w-md text-center">
          <form className="relative" onSubmit={(e) => e.preventDefault()}>
            <input
              type="email"
              placeholder="Email address"
              className="dark:bg-jacarta-700 dark:border-jacarta-600 focus:ring-accent border-jacarta-100 w-full rounded-full border py-3 px-4 dark:text-white dark:placeholder-white"
            />
            <button className="hover:bg-accent-dark font-display bg-accent absolute top-2 right-2 rounded-full px-6 py-2 text-sm text-white">
              Subscribe
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default NewseLatter;
