import "swiper/css";
import "swiper/css/navigation";
import { HeadLine } from "../component";
import "tippy.js/dist/tippy.css";
import AfterConcertCarousel from "../carousel/afterConcertCarousel";

const AfterConcert = ({ classes = "pt-10 pb-24", bgWhite }) => {
  return (
    <section className={classes}>
      {bgWhite && (
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img
            src="/images/gradient_light.jpg"
            alt="gradient"
            className="h-full w-full"
          />
        </picture>
      )}
      <div className="relative md:mx-12">
        <div className="md:flex items-center justify-between m-8">
          <HeadLine
            text="Recent Concerts"
            classes="font-display text-jacarta-700 text-left text-7xl dark:text-white"
          />
          <a href="/events" className="flex items-center gap-2 hover:gap-4 font-display text-red transition-all duration-300 ease-in-out">
            <span>See More</span>
            <img src="/images/icons/arrow-right.svg" width={20} />
          </a>
        </div>
        <AfterConcertCarousel />
      </div>
    </section>
  );
};

export default AfterConcert;
