import React, { useState, useEffect } from "react";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Autoplay, Pagination } from "swiper";
import Image from "next/image";
import {
  hero_swiper_data
} from "../../data/hero";

import { MdKeyboardArrowRight } from "react-icons/md";
import { IoIosInformationCircleOutline } from "react-icons/io";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

const Hero = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024); // Adjust the breakpoint as needed
    };

    handleResize(); // Call on mount

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {/* <!-- Hero Slider --> */}
      <section className="relative h-screen 2xl:h-screen md:max-[980px]:h-[60vh] min-[981px]:max-[1400px]:h-[75vh]"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <Swiper
          modules={[FreeMode, Navigation, Autoplay, Pagination]}
          loop={true}
          spaceBetween={10}
          navigation={{
            nextEl: "#swiper-button-next-home"
          }}
          preload="lazy"
          pagination={{ clickable: true }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          className="full-slider h-screen 2xl:h-screen md:max-[980px]:h-[60vh] min-[981px]:max-[1400px]:h-[75vh]"
        >
          {hero_swiper_data.map((item) => {
            const { image, id, title, name, titleLink, userLink, mobileImage } = item;
            return (
              <SwiperSlide key={id}>
                <div className="swiper-slide after:bg-jacarta-900/40 after:absolute after:inset-0">
                  <div className="relative z-10 h-full pl-8 md:pl-16 flex justify-center flex-col items-start md:max-w-[750px] max-w-[80%]">
                    <h2 className="font-display md:text-6xl text-2xl text-white">
                      <Link legacyBehavior href={titleLink}>
                        <a>{title}</a>
                      </Link>
                    </h2>

                    <Link legacyBehavior href={userLink}>
                      <a className="rounded-md bg-jacarta-base py-2 px-5 text-center font-semibold text-white transition-all hover:bg-accent-dark mt-4 flex items-center gap-1"><IoIosInformationCircleOutline className="mt-[2px]" /> {title == 'Malam Melankolia in Kuala Lumpur' ? 'Event Info' : 'RELIVE THIS CONCERT'}</a>
                    </Link>
                  </div>
                  {isMobile ? (
                    <Image
                      src={mobileImage}
                      className="absolute inset-0 h-full w-full object-cover object-center"
                      alt={title}
                      layout="fill"
                      unoptimized={true}
                      loading="lazy"
                    />
                  ) : (
                    <Image
                      src={image}
                      className="absolute inset-0 h-full w-full object-cover object-center"
                      alt={title}
                      layout="fill"
                      loading="lazy"
                    />
                  )}

                </div>
              </SwiperSlide>
            );
          })}

        </Swiper>
        <div className="z-10 hidden md:block">
          <div id="swiper-button-next-home" className={`group absolute !top-1/2 !right-16 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-jacarta-base p-3 text-white text-xl transition-all duration-300 transform translate-x-full opacity-0 ${isHovered ? 'opacity-100 translate-x-0' : ''}`}>
            <MdKeyboardArrowRight />
          </div>
        </div>
      </section>

    </>
  );
};

export default Hero;
