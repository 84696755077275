import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Image from "next/image";
import "tippy.js/dist/tippy.css";
import { recentConcertData } from "../../data/recent_concert_data";
import Link from "next/link";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { useDispatch } from "react-redux";

const AfterConcertCarousel = () => {
  const dispatch = useDispatch();
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar]}

        slidesPerView="auto"
        parallax={true}
        loop={true}
        centeredSlides={true}
        pagination={{ clickable: true }}
        breakpoints={{
          240: {
            slidesPerView: 1.1,
            spaceBetween: 4
          },
          565: {
            slidesPerView: 2.1,
            spaceBetween: 4
          },
          1000: {
            slidesPerView: 3,
            spaceBetween: 8
          },
          1100: {
            slidesPerView: 3.3,
            spaceBetween: 8
          },
        }}
        navigation={{
          nextEl: ".bids-swiper-button-next",
          prevEl: ".bids-swiper-button-prev",
        }}
        className="recent-concert-slider !overflow-visible"
      >
        {recentConcertData.map((item) => {
          const { id, image, title, date, country, place } =
            item;
          const itemLink = image
            .split("/")
            .slice(-1)
            .toString()
            .replace(".jpg", "");
          return (
            <SwiperSlide className="text-white" key={id}>
              <article>
                <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg text-jacarta-500">
                  <figure>
                    {/* {`item/${itemLink}`} */}
                    <Link legacyBehavior href={"/events/" + itemLink}>
                      <a>
                        <div className="w-full">
                          <Image
                            src={image}
                            alt={title}
                            height={230}
                            width={230}
                            layout="responsive"
                            objectFit="cover"
                            className="rounded-[0.625rem] w-full"
                            loading="lazy"
                          />
                          {title == 'Malam Melankolia in Kuala Lumpur' && (
													<span className='font-bold text-white upcoming rounded-xl py-[8px] px-[16px] top-[32px] right-[32px] absolute'>Upcoming Event</span>
												)}
                        </div>
                      </a>
                    </Link>
                  </figure>
                  <div className="mt-4 flex items-center justify-between">
                    <Link legacyBehavior href={"/events/" + itemLink}>
                      <a>
                        <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                          {title}
                        </span>
                      </a>
                    </Link>
                    {country == 'Indonesia' ? (<img src="/images/icons/indonesia-flag.svg" width={25} />) : (<img src="/images/icons/malaysia-flag.svg" width={25} />)}
                  </div>
                  <div className="lg:flex items-center justify-between w-full block mt-4">
											<div className="flex items-center py-2">
												<img src='/images/icons/calendar-icon.svg' className="icon mr-1 h-3 w-3" />
												<span className="text-green font-display text-xs font-normal">
													{date}
												</span>
											</div>
											<div className="flex items-center py-2">
												<img src='/images/icons/pin-location-icon.svg' className="icon mr-1 h-3 w-3" />
												<span className="text-accent font-display text-xs font-normal">
													{place}
												</span>
											</div>
										</div>
                </div>
              </article>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* <!-- Slider Navigation --> */}
      <div className="group bids-swiper-button-prev swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-left-6 after:hidden !hidden md:!flex">
        <MdKeyboardArrowLeft />
      </div>
      <div className="group bids-swiper-button-next swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-right-6 after:hidden !hidden md:!flex">
        <MdKeyboardArrowRight />
      </div>
    </>
  );
};

export default AfterConcertCarousel;
