const hero_swiper_data = [
	{
		id: 'malam-melankolia',
		image: '/images/hero/image_hero_malam_melankolia.jpg',
		mobileImage: '/images/hero/image_hero_mobile_malam_melankolia.jpg',
		title: 'Malam Melankolia in Kuala Lumpur',
		titleLink: '/events/malam-melankolia',
		userLink: '/events/malam-melankolia',
	},
	{
		id: 'ikon',
		image: '/images/hero/image_hero_ikon.webp',
		mobileImage: '/images/hero/image_hero_mobile_ikon.webp',
		title: '2023 iKON World Tour Take Off',
		titleLink: '/events/ikon',
		userLink: '/events/ikon',
	},
	{
		id: 'oktaecyeon',
		image: '/images/hero/image_hero_oktaecyeon.webp',
		mobileImage: '/images/hero/image_hero_mobile_oktaecyeon.webp',
		title: 'OK TAECYEON in JAKARTA : SpecialTY',
		titleLink: '/events/ok-taecyeon',
		userLink: '/events/ok-taecyeon',
	},
	{
		id: 'akg',
		image: '/images/hero/image_hero_akg.webp',
		mobileImage: '/images/hero/image_hero_mobile_akg.webp',
		title: 'Asian Kung-Fu Generation in Jakarta',
		titleLink: '/events/akg',
		userLink: '/events/akg',
	}

];
export { hero_swiper_data };
