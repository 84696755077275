// import '/images/item_1.jpg' from '/images/item_1.jpg';

const recentConcertData = [
	{
		id: 5,
		image: '/images/carousel/malam-melankolia.jpg',
		title: 'Malam Melankolia in Kuala Lumpur',
		date: "Friday, 3 May 2024",
		country: "Maaysia",
		place: "Zepp, Kuala Lumpur ",
	},
	{
		id: 2,
		image: '/images/carousel/ikon.jpg',
		title: '2023 iKON World Tour Take Off in JAKARTA',
		date: "Sunday, 19 November 2023",
		country: "Indonesia",
		place: "Tennis Indoor Senayan, Jakarta",
	},
	{
		id: 3,
		image: '/images/carousel/ok-taecyeon.jpg',
		title: 'OK TAECYEON in JAKARTA : SpecialTY',
		date: "Saturday, 21 October 2023",
		country: "Indonesia",
		place: "The Kasablanka Hall, Jakarta",
	},
	{
		id: 4,
		image: '/images/carousel/akg.jpg',
		title: 'Asian Kung-Fu Generation in Jakarta',
		date: "Friday, 18 August 2023",
		country: "Indonesia",
		place: "Tennis Indoor Senayan, Jakarta",
	},
	{
		id: 5,
		image: '/images/carousel/crush.jpg',
		title: 'CRUSH HOUR in JAKARTA',
		date: "Tuesday, 8 August 2023",
		country: "Indonesia",
		place: "The Kasablanka Hall, Jakarta",
	}
];

export { recentConcertData };
